// LocationContext.js
import React, { createContext, useState, useEffect } from 'react';

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [isLocationEnabled, setLocationEnabled] = useState(false);


  const enableLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // User has allowed access to their location
          setLocationEnabled(true);
          // You can now do something with the user's location
          console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
        },
        (error) => {
          // User has denied access to their location or an error occurred
          setLocationEnabled(false);
          console.error('Error getting location:', error.message);
        }
      );
    }
  };

  useEffect(() => {
    // Check if location is already enabled when the component mounts
    enableLocation();
  }, []);
  
  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         // User has allowed access to their location
  //         setLocationEnabled(true);
  //       },
  //       (error) => {
  //         // User has denied access to their location or an error occurred
  //         setLocationEnabled(false);
  //       }
  //     );
  //   }
  // }, []);

  return (
    <LocationContext.Provider value={{ isLocationEnabled, enableLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
