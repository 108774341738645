// src/store.js

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './services/authSlice';
import memberReducer from './services/memberSlice';
import memberAgentReducer from './services/memberSlice';
import agentReducer from './services/agentSlice';
import commonReducer from './services/commonSlice';
 import usserReduce from './services/UserSlice';
import anshServiceReducer from './services/anshServiceSlice';
// import productReduce from './services/productSlice';
const store = configureStore({
  reducer: {
     auth: authReducer,
     member: memberReducer,
     memberAgent:memberAgentReducer,
     agent:agentReducer,
     common:commonReducer,
     anshService:anshServiceReducer,
     user:usserReduce,
    // color:colorReduce,
    // product:productReduce
  },
});

export default store;
