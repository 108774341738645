import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {Services_withoutpage} from './Api';



export const without_Services = createAsyncThunk('anshService/without_Services', async (thunkAPI) => {
    try {
       const response = await Services_withoutpage();
       // Extract relevant data from the response
       const responseData = {
           data: response.data,
           status: response.status,
           // You can extract more data if needed
       };
       return responseData;
    } catch (error) {
      throw error.response.data;
    }
});




  export const resetSuccess = () => {
    return {
      type: 'anshService/resetSuccess',
    };
  };


export const anshServiceSlice = createSlice({
    name: 'anshService',
    initialState: {
      isLoading: false,
      isSuccess:false,
      error: null,
      message: '',
      itemsList: []
    },
    reducers: {
       
  },
    extraReducers: (builder) => {
      builder
      
     
      .addCase(without_Services.pending, (state) => {
        console.log("Pending")
        state.islistLoading = true;
      })
      .addCase(without_Services.fulfilled, (state, action) => {
        console.log("fullfill",action.payload.data.message)
       
        if (action.payload.data.success==true) {
        state.itemsList = action.payload.data.message.list;
        state.islistLoading = action.payload.data.success;

        } else {     
         
            state.message = '';
            state.isCommisonSuccess = action.payload.data.success;
            state.itemsList = [];
          
        }
      })
      .addCase(without_Services.rejected, (state, action) => {
        console.log("reject")
        state.islistLoading = false;
        state.error = action.payload;
      })
      
    },
});

export default anshServiceSlice.reducer;
