// todoSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { adharSendOTP,verifyPAN,
  adharVerifyOTP,
  postalCode,
  mobilesendotp,
  mobileverifyotp,
  agent_register,email_checker,Agent_List,agents_package_list,update_onboard_member,
  AgentDropDownListWithName,
  RoleBaseAgent,AgentTransfer,AgentHoldAmount,AdminSendSMS,AgentUnHoldAmount,
  AgentCreditReport,
  AgentDebitReport} from './Api';

export const adhar_SendOTP = createAsyncThunk('member/adhar_SendOTP', async (filter) => {
  const response = await adharSendOTP(filter);
  return response.data;
});

export const adhar_VerifyOTP = createAsyncThunk('member/adhar_VerifyOTP', async (filter) => {
  const response = await adharVerifyOTP(filter);
  return response.data;
});

export const PAN_verify = createAsyncThunk('member/PAN_verify', async (filter) => {
  const response = await verifyPAN(filter);
  return response.data;
});

export const postal_Code = createAsyncThunk('member/postal_Code', async (filter) => {
  const response = await postalCode(filter);
  return response.data;
});


export const mobile_otp = createAsyncThunk('member/mobile_otp', async (filter) => {
  const response = await mobilesendotp(filter);
  return response.data;
});

export const mobile_verify = createAsyncThunk('member/mobile_verify', async (filter) => {
  const response = await mobileverifyotp(filter);
  return response.data;
});

export const newagent_register = createAsyncThunk('member/agent_register', async (filter) => {
  const response = await agent_register(filter);
  return response.data;
});

export const ONBoard_Exist = createAsyncThunk('member/ONBoard_Exist', async (filter) => {
  const response = await update_onboard_member(filter);
  return response.data;
});



export const email_check = createAsyncThunk('member/email_check', async (filter) => {
  const response = await email_checker(filter);
  return response.data;
});


export const agents_List = createAsyncThunk('member/agents_List', async (filter) => {
  const response = await Agent_List(filter);
  return response.data;
});
export const agents_List_package = createAsyncThunk('member/agents_List_package', async (filter) => {
  const response = await agents_package_list(filter);
  return response.data;
});

export const agents_ListWithName = createAsyncThunk('member/agents_ListWithName', async (filter) => {
  const response = await AgentDropDownListWithName(filter);
  return response.data;
});

export const agents_RoleBase = createAsyncThunk('member/agents_RoleBase', async (filter) => {
  const response = await RoleBaseAgent(filter);
  return response.data;
});

export const moveAgent = createAsyncThunk('member/moveAgent', async (filter) => {
  const response = await AgentTransfer(filter);
  return response.data;
});

export const HoldAmount_Agent = createAsyncThunk('member/HoldAmount_Agent', async (filter) => {
  const response = await AgentHoldAmount(filter);
  return response.data;
});

export const UnHoldAmount_Agent = createAsyncThunk('member/UnHoldAmount_Agent', async (filter) => {
  const response = await AgentUnHoldAmount(filter);
  return response.data;
});


export const AdminSendSMSToAgent = createAsyncThunk('member/AdminSendSMSToAgent', async (filter) => {
  const response = await  AdminSendSMS(filter);
  return response.data;
});
export const agent_Credit_reports = createAsyncThunk('member/agent_Credit_reports', async (filter) => {
  const response = await  AgentCreditReport(filter);
  return response.data;
});

export const agent_Debit_reports = createAsyncThunk('member/agent_Debit_reports', async (filter) => {
  const response = await  AgentDebitReport(filter);
  return response.data;
});



const memberSlice = createSlice({
  name: 'member',
  initialState: {
    isLoading: false,
    error: null,
    otpsuccess:false,
    pansuccess:false,
    aadharsuccess:false,
    message: '',
    itemsList: [],
    itemCount: 0,
    perPage: 10,
    page: 1,
    pageCount: 0,
    itemsListInPackage: [],
    itemCountpackage: 0,
    perPagepackage: 10,
    pagepackage: 1,
    pageCountpackage: 0,
    list:[],
    roleBaseList:[],
    legerReport:[],
    debitlegerReport:[]
  },


  reducers: {},
  extraReducers: (builder) => {
    builder
    
    .addCase(adhar_SendOTP.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(adhar_SendOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload;
    })
    .addCase(adhar_SendOTP.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(adhar_VerifyOTP.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(adhar_VerifyOTP.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(adhar_VerifyOTP.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(PAN_verify.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(PAN_verify.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(PAN_verify.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    }) 
    .addCase(postal_Code.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(postal_Code.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(postal_Code.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(mobile_otp.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(mobile_otp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(mobile_otp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(mobile_verify.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(mobile_verify.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(mobile_verify.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(newagent_register.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(newagent_register.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(newagent_register.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(ONBoard_Exist.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(ONBoard_Exist.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      console.log(action.payload)
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(ONBoard_Exist.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    
    .addCase(email_check.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(email_check.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(email_check.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    .addCase(agents_List.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(agents_List.fulfilled, (state, action) => {
      console.log(action.payload.message.agents);
      console.log(action.payload.message.agents.itemCount);

      if(action.payload.success==true){

        state.itemsList = action.payload.message.agents.itemsList;
        state.itemCount = action.payload.message.agents.itemCount;        ;
        state.perPage = action.payload.message.agents.perPage;
        state.page = action.payload.message.agents.currentPage;
        state.pageCount = action.payload.message.agents.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.itemsList = [];
      state.itemCount = 0;
      state.perPage = 10;
      state.page = 1;
      state.pageCount = 0;
    }
    state.isLoading = false;
     
    })
    .addCase(agents_List.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(agents_List_package.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(agents_List_package.fulfilled, (state, action) => {
      console.log(action.payload.message.agents);
      console.log(action.payload.message.agents.itemCount);

      if(action.payload.success==true){

        state.itemsListInPackage = action.payload.message.agents.itemsList;
        state.itemCountpackage = action.payload.message.agents.itemCount;        ;
        state.perPagepackage = action.payload.message.agents.perPage;
        state.pagepackage = action.payload.message.agents.currentPage;
        state.pageCountpackage = action.payload.message.agents.pageCount;
        state.success = action.payload.success;
    }else{
      state.message = '';
      state.success = action.payload.success;
      state.itemsListInPackage= [];
      state.itemCountpackage= 0;
      state.perPagepackage= 10;
      state.pagepackage= 1;
      state.pageCountpackage=0;
    }
    state.isLoading = false;
     
    })
    .addCase(agents_List_package.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })


   .addCase(agent_Credit_reports.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(agent_Credit_reports.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.legerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.legerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(agent_Credit_reports.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })


    .addCase(agent_Debit_reports.pending, (state) => {
      state.isLoading = true;
    
    })
    .addCase(agent_Debit_reports.fulfilled, (state, action) => {
    
      if (action.payload.success==true) {
        state.debitlegerReport = action.payload.message.transationList.itemsList;
        state.itemCount = action.payload.message.transationList.itemCount;
        state.perPage = action.payload.message.transationList.perPage;
        state.page = action.payload.message.transationList.currentPage;
        state.pageCount = action.payload.message.transationList.pageCount;
        state.success = true;
      } else {
        // Reset state if no data returned or unsuccessful
        state.debitlegerReport = [];
        state.itemCount = 0;
        state.perPage = 10;
        state.page = 1;
        state.pageCount = 0;
        state.success = false;
      }
      state.isLoading = false;
    })
    
    .addCase(agent_Debit_reports.rejected, (state, action) => {
     
      state.isLoading = false;
      state.error = action.payload;
    })


    
      
    .addCase(agents_ListWithName.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(agents_ListWithName.fulfilled, (state, action) => {
      console.log(action.payload.message.agents,"Withname");
      
      if(action.payload.success==true){
        state.list = action.payload.message.agents;
        state.success = action.payload.success;
    }else{
     
      state.success = action.payload.success;
      state.list = [];
    }
    state.isLoading = false;
     
    })
    .addCase(agents_ListWithName.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(agents_RoleBase.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(agents_RoleBase.fulfilled, (state, action) => {
      console.log(action.payload.message.agents,"Withname");
      
      if(action.payload.success==true){
        state.roleBaseList = action.payload.message.agents;
        state.success = action.payload.success;
    }else{
     
      state.success = action.payload.success;
      state.roleBaseList = [];
    }
    state.isLoading = false;
     
    })
    .addCase(agents_RoleBase.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })

    .addCase(moveAgent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(moveAgent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(moveAgent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })

    .addCase(HoldAmount_Agent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(HoldAmount_Agent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(HoldAmount_Agent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })


    .addCase(UnHoldAmount_Agent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(UnHoldAmount_Agent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(UnHoldAmount_Agent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })

    
    .addCase(AdminSendSMSToAgent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
      state.success = false;
    })
    .addCase(AdminSendSMSToAgent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.success = action.payload.success;
      state.message = action.payload.data;
    })
    .addCase(AdminSendSMSToAgent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.success = false;
    })
    
    
  },
});

export default memberSlice.reducer;
