import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menuitem from './Menuitem';

const AdminPrivateRoute = ({ element: Element, allowedRoles, isProtected, ...rest }) => {
  const { isLogin, isToken, isRole } = useSelector((state) => state.auth);

  // Check if the user is logged in and has the appropriate role
  const isAdminLoggedIn = isLogin && isToken && isRole === 'Superadmin';

  if (!isAdminLoggedIn || !allowedRoles.includes(isRole)) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Element {...rest} />
    </React.Suspense>
  );
};

const AgentPrivateRoute = ({ element: Element, allowedRoles, isProtected, ...rest }) => {
  const { agent_isLogin, agent_isToken, agent_isRole,agent_isKyc } = useSelector((state) => state.agent);

  // Check if the user is logged in and has the appropriate role
  const isAgentLoggedIn = agent_isLogin && agent_isToken && agent_isRole === 'agents';

    // Check if the route is protected and the user's role is allowed
    if (isProtected && (!isAgentLoggedIn || !allowedRoles.includes(agent_isRole))) {
      // If the user is not logged in or their role is not allowed for this route, redirect to agent login
      if (!isAgentLoggedIn) {
        return <Navigate to="/agents/login" />;
      }
    }

    if (isProtected && allowedRoles.includes(agent_isRole) && agent_isKyc == "0" &&rest.isKyc || agent_isKyc == "2"  && rest.isKyc) {
      return <Navigate to="/agents/kyc_update" />;
    }


    if (agent_isKyc == '1' && rest.children=='KYC Update') {
       return <Navigate to="/agents/dashboard" />;
    }

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <Element {...rest} />
    </React.Suspense>
  );
};


const NotFound = () => {
  return (
    <div>
      <h2>Page Not Found</h2>
    </div>
  );
};

const RoutesConfig = () => {
  return (
    <Routes>
      {Menuitem.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.isProtected === 1 && route.allowedRoles.includes('Superadmin') ?
              <AdminPrivateRoute element={route.layout} allowedRoles={route.allowedRoles} isProtected={route.isProtected} children={route.name} /> :
              <AgentPrivateRoute element={route.layout} allowedRoles={route.allowedRoles} isProtected={route.isProtected} children={route.name} isKyc={route.isKyc}  />
          }
        >
          <Route index element={<route.component />} />
          {route.children && route.children.map((childRoute, childIndex) => (
            <Route
              key={childIndex}
              path={childRoute.path}
              element={
                childRoute.isProtected === 1 && childRoute.allowedRoles.includes('Superadmin') ?
                  <AdminPrivateRoute element={childRoute.layout} allowedRoles={childRoute.allowedRoles} isProtected={childRoute.isProtected} children={childRoute.name} /> :
                  <AgentPrivateRoute element={childRoute.layout} allowedRoles={childRoute.allowedRoles} isProtected={childRoute.isProtected} children={childRoute.name} isKyc={childRoute.isKyc} />
              }
            />
          ))}
        </Route>
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default RoutesConfig;
