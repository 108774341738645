import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { LoginOTPService, LoginVerifyService, LogoutandOTPService, LogoutVerifyService ,Admin_Logout} from './Api';

// Async thunk for handling login
export const loginUser = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
    try {
       const response = await LoginOTPService(credentials);
       console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});

export const logoutandcontinueUser = createAsyncThunk('auth/logoutandcontinue', async (credentials, thunkAPI) => {
    try {
       const response = await LogoutandOTPService(credentials);
       console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});

export const loginUserVerify = createAsyncThunk('auth/verify', async (credentials, thunkAPI) => {
    try {
       const response = await LoginVerifyService(credentials);
       console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});

export const logoutUserVerify = createAsyncThunk('auth/logoutverify', async (credentials, thunkAPI) => {
    try {
       const response = await LogoutVerifyService(credentials);
     //  console.log(response);
       return response;
    } catch (error) {
      throw error.response.data;
    }
});
/*
export const logoutAdmin = createAsyncThunk('auth/logoutAdmin', async (credentials, thunkAPI) => {
  try {
     const response = await Admin_Logout(credentials);
     console.log(response);
     return response;
  } catch (error) {
    throw error.response.data;
  }
});*/

export const logoutAdmin = createAsyncThunk('auth/logoutAdmin', async () => {
  const response = await Admin_Logout();
  return response.data;
});

export const resetLogoutSuccess = () => {
    return {
      type: 'auth/resetLogoutSuccess',
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
      user: null,
      newsuccess: false,
      isLoading: false,
      logoutsuccess: false,
      error: null,
      message: '',
      isToken: localStorage.getItem("admin_isToken")  || null,
      isRole: localStorage.getItem("admin_isRole") || null,
      isLogin: localStorage.getItem("admin_isLogin")  || false,
  
      // isToken: null,
      // isRole: null,
      // isLogin: false,
    },
    reducers: {
      logoutUser: (state) => {
        state.user = null;
        state.message = '';
        state.isRole = null;
      },
      resetLogoutSuccess: (state) => {
        state.logoutsuccess = false;
        state.newsuccess = false;
        state.finalsuccess = false;
        state.success = false;
        state.message = '';
        state.isRole = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(loginUser.pending, (state) => {
          state.isLoading = true;
          state.success = false;
          state.message = '';
        })
        .addCase(loginUser.fulfilled, (state, action) => {
          if (action.payload.data.success==true) {

           

            state.isLoading = false;
            state.user = action.payload.data;
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;
            state.isLogin = action.payload.data.success;
            state.isToken = action.payload.authToken;
            state.isRole = action.payload.data.message.role;

           

          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.success = action.payload.data.success;

          }
        })
        .addCase(loginUser.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(loginUserVerify.pending, (state) => {
          state.isLoading = true;
          state.success = false;
          state.finalsuccess = false;
          state.message = '';
        })
        .addCase(loginUserVerify.fulfilled, (state, action) => {
          console.log(action.payload.authToken)
          if (action.payload.data.success) {
            state.isLoading = false;
            state.user = action.payload.data;
            state.message = action.payload.data.message;
            state.success = true;
            state.finalsuccess = action.payload.data.success;
            state.isLogin = action.payload.data.success;
            state.isToken = action.payload.authToken;
            state.isRole = action.payload.data.message.role;

            localStorage.setItem("admin_isLogin",action.payload.data.success)
            localStorage.setItem("admin_isToken",action.payload.authToken)
            localStorage.setItem("admin_isRole",action.payload.data.message.role)
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.finalsuccess = action.payload.data.success;
            state.success = false;
            state.isLogin = false;
            state.isToken = null;
            state.isRole = null;
          }
        })
        .addCase(loginUserVerify.rejected, (state, action) => {
          state.isLoading = false;
          state.success = false;
          state.error = action.error.message;
        })
        .addCase(logoutandcontinueUser.pending, (state) => {
          state.isLoading = true;
          state.newsuccess = false;
          state.message = '';
        })
        .addCase(logoutandcontinueUser.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.newsuccess = action.payload.data.success;
          }
        })
        .addCase(logoutandcontinueUser.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })
        .addCase(logoutUserVerify.pending, (state) => {
          state.isLoading = true;
          state.logoutsuccess = false;
          state.message = '';
        })
        .addCase(logoutUserVerify.fulfilled, (state, action) => {
          if (action.payload.data.success) {
            state.isLoading = false;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
          } else {
            state.isLoading = true;
            state.message = action.payload.data.message;
            state.logoutsuccess = action.payload.data.success;
          }
        })
        .addCase(logoutUserVerify.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        })

        .addCase(logoutAdmin.pending, (state) => {
          state.isLoading = true;
          console.log("action.payload.message.agents5555555");
        })
        .addCase(logoutAdmin.fulfilled, (state, action) => {
          console.log(action.payload.message,"=====================");
          if (action.payload.success==true) {
            state.success = true;
          } else {
            // Reset state if no data returned or unsuccessful
            state.success = false;
          }
          state.isLoading = false;
        })
        
        .addCase(logoutAdmin.rejected, (state, action) => {
          console.log("action.payload.message.agents");
          state.isLoading = false;
          state.error = action.payload;
        })
    },
});

export const { logoutUser } = authSlice.actions;

export default authSlice.reducer;
